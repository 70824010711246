import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import Headroom from "headroom.js";
// import "../js/modules/_cookiebanner.js";
// FANCYBOX DISABLES WAVE EXTENSION - REMOVE FOR NOW
import { Fancybox } from "@fancyapps/ui";

function init() {
  // start here

  // console.log('/app.js');

  // FANCYBOX
  Fancybox.bind("[data-fancybox]", {
    // Your options go here
  });

  // ALPINE
  window.Alpine = Alpine;
  Alpine.plugin(focus);
  Alpine.plugin(collapse);
  Alpine.start();

  // HEADROOM
  if (Headroom.cutsTheMustard) {
    // HEADROOM
    var headerElement = document.querySelector("header");
    // var headroom  = new Headroom(headerElement);
    const headroom = new Headroom(headerElement, {
      // offset: {
      // 	up: 50,
      // 	down: 200,
      // },
      offset: headerElement.offsetHeight,
      tolerance: {
        up: 5,
        down: 5,
      },

      // classes: {
      // 	pinned: 'is-showing',
      // 	unpinned: 'fnav:-translate-y-40',
      // 	top: 'is-top',
      // 	notTop: 'is-not-top',
      // },
    });
    headroom.init();
  }

  // SCROLL TO ANCHOR
  function anchor() {
    const anchorLinks = document.querySelectorAll(".scroll");

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", anchorLinkClick);
    }

    function anchorLinkClick(event) {
      smoothScroll(event);
    }
    function smoothScroll(event) {
      event.preventDefault();
      const targetId =
        event.currentTarget.getAttribute("href") === "#"
          ? "header"
          : event.currentTarget.getAttribute("href");
      const targetPosition = document.querySelector(targetId).offsetTop - 30;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition;
      const duration = 1000;
      let start = null;

      window.requestAnimationFrame(step);

      function step(timestamp) {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        window.scrollTo(
          0,
          easeInOutCubic(progress, startPosition, distance, duration)
        );
        if (progress < duration) window.requestAnimationFrame(step);
      }
    }

    function linear(t, b, c, d) {
      return (c * t) / d + b;
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    function easeInOutCubic(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t * t + b;
      t -= 2;
      return (c / 2) * (t * t * t + 2) + b;
    }
  }
  const anchorLink = document.querySelector(".scroll");
  if (anchorLink) {
    anchor();
  }
}

window.addEventListener("load", function () {
  init();
});

window.addEventListener("DOMContentLoaded", () => {
  // TAB STUFF
  const tabs = document.querySelectorAll('[role="tab"]');
  const tabList = document.querySelector('[role="tablist"]');

  if (tabList) {
    // Add a click event handler to each tab
    tabs.forEach((tab) => {
      tab.addEventListener("click", changeTabs);
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener("keydown", (e) => {
      // Move right
      if (e.keyCode === 39 || e.keyCode === 37) {
        tabs[tabFocus].setAttribute("tabindex", -1);
        if (e.keyCode === 39) {
          tabFocus++;
          // If we're at the end, go to the start
          if (tabFocus >= tabs.length) {
            tabFocus = 0;
          }
          // Move left
        } else if (e.keyCode === 37) {
          tabFocus--;
          // If we're at the start, move to the end
          if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
          }
        }
        tabs[tabFocus].setAttribute("tabindex", 0);
        tabs[tabFocus].focus();
      }
    });
  }
});

function changeTabs(e) {
  const target = e.target;
  const parent = target.parentNode;
  const grandparent = parent.parentNode;

  // Remove all current selected tabs
  parent
    .querySelectorAll('[aria-selected="true"]')
    .forEach((t) => t.setAttribute("aria-selected", false));

  // Set this tab as selected
  target.setAttribute("aria-selected", true);

  // Hide all tab panels
  grandparent
    .querySelectorAll('[role="tabpanel"]')
    .forEach((p) => p.setAttribute("hidden", true));

  // Show the selected panel
  grandparent.parentNode
    .querySelector(`#${target.getAttribute("aria-controls")}`)
    .removeAttribute("hidden");
}
